<template>
  <b-overlay
      :show="users === null"
      rounded="sm"
  >

  <div v-if="users !== null">

    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      @refetch-data="refreshData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <span> تعداد کاربران : </span>
            <span class="mx-1" >{{totalCounts}}</span>
            <span >نفر</span>
          </b-col>

          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>نمایش</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>تعداد</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <debouncer :placeHolder="`جستجو`" @setValue="getsearchValue"></debouncer>
<!--              <b-form-input-->
<!--                v-model="debouncedSearchQuery"-->
<!--                class="d-inline-block mr-1"-->
<!--                placeholder="جستجو..."-->
<!--              />-->
<!--              <b-button-->
<!--                variant="primary"-->
<!--                @click="isAddNewUserSidebarActive = true"-->
<!--              >-->
<!--                <span class="text-nowrap">اضافه کردن کاربر</span>-->
<!--              </b-button>-->
            </div>
          </b-col>
          <b-col cols="12" lg="2">
            <clear-sorts-btn @clear="sortBy = ''"></clear-sorts-btn>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="users"
        :sort-by.sync="sortBy"
        responsive
        :fields="myTableColumns"
        primary-key="id"
        show-empty
        bordered
        striped
        empty-text="موردی موجود نیست!"
      >

        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
                            <b-avatar
                              size="45"
                              :src="baseURL+data.item.profileImage"
                              :variant="`success`"
                              :to="{ name: 'apps-users-edit', params: { id: data.item.userId } }"
                            />
            </template>
                        <b-link
                          :to="{ name: 'apps-users-edit', params: { id: data.item.userId } }"
                          class="font-weight-bold d-block text-nowrap"
                        >
                          {{ data.item.firstName+' '+data.item.lastName }}
                        </b-link>
            <small class="text-muted">@{{ data.item.userId }}</small>
          </b-media>
        </template>

        <template #cell(userRole)="data">

          <span v-if="data.item.userRole === 'Customer' " >کاربر عادی</span>
          <span v-if="data.item.userRole === 'Vendor' " class="text-success">فروشنده</span>
          <span v-if="data.item.userRole === 'Admin' " class="text-success">سوپر ادمین</span>

        </template>

        <template #cell(state)="data">

          <span v-if="data.item.status === true" class="text-success">فعال</span>
          <span v-if="data.item.status === false" class="text-danger">غیر فعال</span>

        </template>

        <!-- Column: edit -->
        <template #cell(edit)="data">
          <b-link :to="{ name: 'apps-users-edit', params: { id: data.item.userId } }">
            <feather-icon icon="EditIcon" size="20"/>
          </b-link>
        </template>

        <!-- Column: createDate -->
        <template #cell(createDate)="data">
            <span>
              {{data.item.createDate.slice(11,16)}}
              -
              {{createJalaliDate(data.item.createDate.slice(0,10))}}
            </span>
        </template>

        <!-- Column: chat -->
        <template #cell(chat)="data">
          <b-link :to="{ name: 'apps-chats-id', params: { id: data.item.userId } }">
            <feather-icon icon="MessageCircleIcon" size="20"/>
          </b-link>
        </template>


<!--        &lt;!&ndash; Column: factors &ndash;&gt;-->
<!--        <template #cell(factors)="data">-->
<!--          <b-link :to="{ name: 'apps-users-factors', params: { id: data.item.userId } }">-->
<!--            <feather-icon icon="ListIcon" />-->
<!--          </b-link>-->
<!--        </template>-->

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
<!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCounts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon style="transform: rotate(180deg)"
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay,BInputGroup, BInputGroupAppend
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import UserListAddNew from './UserListAddNew.vue'
import {UsersGetAllRequest} from "@/libs/Api/Users";
import Helper from "@/libs/Helper";
import ClearSortsBtn from "@/views/components/clearSortsBtn.vue";
import Debouncer from "@/views/components/debouncer.vue";

export default {
  title:"پنل ادمین",
  data(){
    return{
      users:null,
      baseURL:Helper.baseUrl,
      currentPage:1,
      totalCounts:null,
      sortBy:'',
      perPage:10,
      perState:'',
      perStateOptions:[
        {label:'همه',value:''},
        {label:'وارد شده',value:1},
        {label:'فالو نکرده',value:2},
        {label:'آیدی اشتباه',value:3}
      ],
      perPageOptions:[10, 20, 30 , 40 , 50],
      myTableColumns : [
        { key: 'name' ,label :'نام کامل',sortable:true},
        { key: 'userName' ,label :'نام کاربری',sortable:true},
        { key: 'createDate' ,label :'تاریخ عضویت',sortable:true},
        { key: 'userRole' ,label :'نوع کاربر',sortable:true},
        { key: 'mobile',label: 'موبایل',sortable:true},
        { key: 'email',label: 'ایمیل',sortable:true},
        { key: 'edit',label:'ویرایش کاربر'},
        // { key: 'chat',label:'چت'},
        // { key: 'factors',label:'فاکتورهای کاربر'},
      ],
      searchQuery:'',
      isAddNewUserSidebarActive:false,
      timeout:null,
    }
  },
  async created() {
    await this.getUsers(this.perPage,this.currentPage,this.searchQuery)
  },
  methods:{
    getsearchValue(val){
      this.searchQuery = val
      this.getUsers(this.perPage,this.currentPage,this.searchQuery);

    },
    refreshData(){
      this.getUsers(this.perPage,this.currentPage,this.searchQuery)
    },
    async getUsers(count,pageNumber,search){
      let _this = this;
      let data = {
        pageNumber:pageNumber,
        count:count,
        searchCommand:search,
      }

      let usersGetAllRequest = new UsersGetAllRequest(_this);
      usersGetAllRequest.setParams(data);
      await usersGetAllRequest.fetch(function (content){
        _this.users = content.users;
        _this.totalCounts = content.usersCount;
      },function (error){
        console.log(error);
      })
    },
    createJalaliDate(param){
      return new Date(param).toLocaleDateString("fa-IR",);
    },
  },

  watch:{
    perPage:function (nv,ov){
      this.getUsers(nv,this.currentPage,this.searchQuery);
    },
    currentPage:function (nv,ov) {
      this.getUsers(this.perPage,nv,this.searchQuery);
    },

  },
  components: {
    Debouncer,
    ClearSortsBtn,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BInputGroup, BInputGroupAppend,

    vSelect,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
